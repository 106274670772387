import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { LoginResponse } from '@/models/login-response.model';

type Response = LoginResponse & AxiosError;

export const loginService = async (formData: FormData): Promise<Response> => {
  setLoading();
  try {
    const response = await getApiClient().post('/api/v1/login', formData);
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
