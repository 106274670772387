import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { getStoredToken } from '../session/account-store.service';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { AccountTransactionsResponse } from '@/models/account-transactions-response.model';

type Response = AccountTransactionsResponse & AxiosError;

export const userTransactionsService = async (
  query: string
): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const response = await getApiClient('auth', token ?? '').get(
      `/api/v1/account/transactions?${query}`
    );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
