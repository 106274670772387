import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { ForgotPasswordResponse } from '@/models/forgot-password-response.model';

type Response = ForgotPasswordResponse & AxiosError;

export const forgotPasswordService = async (
  formData: FormData
): Promise<Response> => {
  setLoading();
  try {
    const response = await getApiClient().post(
      '/api/v1/email-forgot-password',
      formData
    );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
