import { defineStore } from 'pinia';

interface AppStoreState {
  loading: boolean;
  validating: boolean;
}

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => {
    return {
      loading: false,
      validating: false,
    } as AppStoreState;
  },
  getters: {
    isLoading: (state) => state.loading,
    isValidating: (state) => state.validating,
  },
  actions: {
    setLoading() {
      this.loading = true;
    },
    setValidating() {
      this.validating = true;
    },
  },
});
