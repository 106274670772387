import { useFundraisingStore } from '@/store/fundraising.store';
import {getSiteAffiliate} from '@/services/session/affiliate.service';

export const checkStep1 = async (): Promise<boolean> => {
  const fundraisingStore = useFundraisingStore();
  if (fundraisingStore.getStep1.amount > 0) {
    return true;
  }
  const userAboutNotRequiredAffiliates = ['USA', 'France'];
  if (fundraisingStore.step1.userAbout) {
    return true;
  }
  if (userAboutNotRequiredAffiliates.includes(getSiteAffiliate())) {
    return true
  }
  return false;
};

// export const checkStep2 = async (): Promise<boolean> => {
//   const fundraisingStore = useFundraisingStore();
//   const step2NotRequiredAffiliates = ['USA', 'France'];
//   if (fundraisingStore.step1.userAbout) {
//     return true;
//   }
//   if (step2NotRequiredAffiliates.includes(getSiteAffiliate())) {
//     return true
//   }
//   return false;
// };

export const checkStep3 = async (): Promise<boolean> => {
  const fundraisingStore = useFundraisingStore();
  if (fundraisingStore.step3.confirmPreview) {
    return true;
  }
  return false;
};
