import { apiServices } from '@/services/api';
import { useAuthStore } from '@/store/auth.store';
import { useUserStore } from '@/store/user.store';
import { useMarketingPreferencesStore } from '@/store/marketing-preferences.store';
import { getSiteAffiliate } from './affiliate.service';
import { Login } from '@/models/login.model';
import { Registration } from '@/models/registration.model';
import { SecurityPassword } from '@/models/security-password.model';
import { AccountPasswordChangeRequest } from '@/models/account-password-change-request.model';
import { MarketingPreferences } from '@/models/marketing-preferences.model';
import { AccountMarketingPreferencesUpdateRequest } from '@/models/account-marketing-preferences-update-request.model';
import {
  clearMessages,
  errorMessage,
  statusMessage,
} from '../utils/message.service';
import { syncUserStoreToStorage } from './user-storage-sync.service';
import { setStoredMarketingPreferences } from './account-store.service';
import { AddressPayload } from '@/modules/address-lookup/services/address-lookup-api.config';
import { getAddress } from '@/modules/address-lookup/services/address-lookup.service';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { datalayerAccountPreferencesUpdate } from '../utils/google-analytics-datalayer.service';
import { useTransactionStore } from '@/store/transactions.store';

export const login = async (values: Login): Promise<boolean> => {
  const formData = new FormData();
  formData.append('email', values.email);
  formData.append('password', values.password);
  formData.append('affiliate', getSiteAffiliate());

  const login = await apiServices.loginService(formData);
  if (!login.isAxiosError) {
    const authStore = useAuthStore();
    authStore.setLoggedIn(login.token);
    const userStore = useUserStore();
    userStore.setUserNameFromLogin(login.user);
    await syncUserStoreToStorage();
    if (authStore.getToken) {
      return true;
    }
  }

  errorMessage('loginUnauthorizedErrorMessage');
  return false;
};

export const register = async (values: Registration): Promise<boolean> => {
  setLoading();
  const registeredAccount = await registerAccount(values);
  if (!registeredAccount) {
    clearLoading();
    return false;
  }

  const marketingPreferences = {
    email: values.marketingEmail,
    post: values.marketingPost,
    postAddress: values.address,
  } as MarketingPreferences;
  const registeredMarketingPreferences = await userMarketingPreferencesUpdate(
    marketingPreferences
  );
  if (!registeredMarketingPreferences) {
    clearLoading();
    return false;
  }

  if (registeredAccount && registeredMarketingPreferences) {
    clearLoading();
    clearMessages();
    return true;
  }

  return false;
};

export const registerAccount = async (
  values: Registration
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('first_name', values.firstName);
  formData.append('last_name', values.lastName);
  formData.append('email', values.email);
  formData.append('password', values.password);
  formData.append('confirm_password', values.confirmPassword);
  formData.append('affiliate', getSiteAffiliate());

  const register = await apiServices.registerService(formData);
  if (!register.isAxiosError) {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    authStore.setLoggedIn(register.token);
    userStore.setUserEmailFromRegister(register.user);
    if (authStore.getToken) {
      return true;
    }
  }

  errorMessage('registerErrorMessage');
  return false;
};

export const logout = async (): Promise<void> => {
  const confirmation = await apiServices.logoutService();
  if (confirmation) {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const marketingPreferencesStore = useMarketingPreferencesStore();
    const transactionStore = useTransactionStore();
    authStore.setLoggedOut();
    userStore.setLoggedOut();
    marketingPreferencesStore.setLoggedOut();
    transactionStore.setLoggedOut();
    statusMessage('logoutMessage');
  } else {
    errorMessage('logoutErrorMessage');
  }
};

export const sendForgotPassword = async (email: string): Promise<void> => {
  const affiliate = getSiteAffiliate();
  const formData = new FormData();
  formData.append('affiliate', affiliate);
  formData.append('email', email);
  const confirmation = await apiServices.forgotPasswordService(formData);
  if (!confirmation.isAxiosError) {
    statusMessage('forgotPasswordMessage');
  } else {
    errorMessage('forgotPasswordErrorMessage');
  }
};

export const emailExists = async (
  email: string | undefined
): Promise<boolean> => {
  if (email) {
    const formData = new FormData();
    formData.append('email', email);
    const exists = await apiServices.emailExistsService(formData);
    return exists;
  }
  return false;
};

export const userDetails = async (): Promise<boolean> => {
  const details = await apiServices.userDetailsService();
  if (!details.isAxiosError) {
    const userStore = useUserStore();
    userStore.setUser(details.data);
    return true;
  } else {
    errorMessage('logoutErrorMessage');
    return false;
  }
};

export const userChangePassword = async (
  values: SecurityPassword
): Promise<boolean> => {
  const payload = {
    password_current: values.passwordCurrent,
    password: values.password,
    password_confirm: values.passwordConfirm,
  } as AccountPasswordChangeRequest;
  const update = await apiServices.changePasswordService(payload);
  if (!update.isAxiosError) {
    statusMessage('changePasswordMessage');
    return update;
  } else {
    errorMessage('changePasswordErrorMessage');
    return false;
  }
};

export const userMarketingPreferences = async (): Promise<boolean> => {
  const preferences = await apiServices.marketingPreferencesService();
  if (!preferences.isAxiosError) {
    await setStoredMarketingPreferences(preferences.data);
    return true;
  } else {
    errorMessage('marketingPreferencesErrorMessage');
    return false;
  }
};

const generateMarketingPreferencesUpdatePayload = (
  values: MarketingPreferences
): AccountMarketingPreferencesUpdateRequest => {
  const payload = {
    post: values.post,
    email: values.email,
  } as AccountMarketingPreferencesUpdateRequest;

  if (payload.post) {
    const address = getAddress();
    if (address) {
      const firstLine =
        address?.address2 && address?.address2.length > 0
          ? [address?.address1, address?.address2].join(', ')
          : address?.address1;
      payload.postAddress = {
        firstLine,
        city: address?.townCity,
        region: address?.region,
        postCode: address?.postcode,
        country: address?.country,
      } as AddressPayload;
    }
  }

  return payload;
};

export const userMarketingPreferencesUpdate = async (
  values: MarketingPreferences
): Promise<boolean> => {
  const payload = generateMarketingPreferencesUpdatePayload(values);
  const update = await apiServices.updateMarketingPreferencesService(payload);
  if (!update.isAxiosError) {
    datalayerAccountPreferencesUpdate(values);
    await setStoredMarketingPreferences(values);
    statusMessage('marketingPreferencesUpdateMessage');
    return true;
  } else {
    errorMessage('marketingPreferencesUpdateErrorMessage');
    return false;
  }
};
