import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import { GDialog } from 'gitart-vue-dialog';
import { vue3Debounce } from 'vue-debounce';

import App from './App.vue';
import router from './router';
import { getLanguageFile } from './i18n';

import './assets/main.css'

//Redundant import of bootstrap package
// Import Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap JS bundle (includes Popper.js)
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';



const langCode = document.documentElement.lang || 'en';

getLanguageFile(langCode).then((messages) => {
  const i18n = createI18n({
    legacy: false,
    locale: langCode,
    fallbackLocale: 'en',
    globalInjection: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messages: messages as any,
  });
  createApp(App)
    .use(i18n)
    .use(createPinia())
    .use(router)
    .component('GDialog', GDialog)
    .directive('debounce', vue3Debounce({ lock: true }))
    .mount('#app');
});
