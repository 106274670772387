import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { getStoredToken } from '../session/account-store.service';
import { getSiteAffiliate } from '../session/affiliate.service';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { AccountUserResponse } from '@/models/account-user-response.model';

type Response = AccountUserResponse & AxiosError;

export const userDetailsService = async (): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const affiliate = getSiteAffiliate();
    const response = await getApiClient('auth', token ?? '').get(
      `/api/v1/account/personal-details/${affiliate}`
    );
    // const response = await apiClient.get(
    //   `http://localhost:5000/account/personal-details/${affiliateId}`
    // ); // Mock
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
