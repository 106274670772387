import { useAuthStore } from '@/store/auth.store';
import { syncAuthStorageToStore } from '@/services/session/auth-storage-sync.service';
import { statusMessage } from '@/services/utils/message.service';

export const loggedOutGuard = async (
  redirect: string
): Promise<string | null> => {
  const authStore = useAuthStore();
  if (!authStore.isLoggedIn) {
    const hasSession = await syncAuthStorageToStore();
    if (hasSession) {
      return redirect;
    } else {
      statusMessage('loginToContinueMessage');
      return 'Login';
    }
  } else {
    return null;
  }
};

export const loggedInGuard = async (): Promise<string | null> => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    return 'Home';
  } else {
    const hasSession = await syncAuthStorageToStore();
    if (hasSession) {
      return 'Home';
    } else {
      return null;
    }
  }
};

export const authEmailGuard = async (): Promise<boolean> => {
  const authStore = useAuthStore();
  if (!authStore.getEmail) {
    statusMessage('loginToContinueMessage');
    return false;
  }
  return true;
};
