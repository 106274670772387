export interface AccountUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    telephone_number: string;
    postcode: string;
    address: string;
    total_opp_amount: number;
    number_of_closed_opps: number;
    opp_amount_this_year: number;
    opp_amount_last_year: number;
    opp_amount_2_years_ago: number;
    number_of_soft_credits: number;
    soft_credit_total: number;
    has_active_recurring_donation: boolean
    last_close_date: string;
    first_close_date: string;
    cost_per_child: number;
    mailing_street: string;
    mailing_city: string;
    mailing_state: string;
    mailing_postal_code: string;
    mailing_country: string;
}

export const InitialAccountUserState: AccountUser = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    telephone_number: '',
    postcode: '',
    address: '',
    number_of_closed_opps: 0,
    total_opp_amount: 0,
    opp_amount_this_year: 0,
    opp_amount_last_year: 0,
    opp_amount_2_years_ago: 0,
    number_of_soft_credits: 0,
    soft_credit_total: 0,
    has_active_recurring_donation: false,
    last_close_date: '',
    first_close_date: '',
    cost_per_child: 19.50,
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_postal_code: '',
    mailing_country: '',

};
