import { useTransactionStore } from '@/store/transactions.store';
import { userTransactions } from '@/services/session/transactions.service';
import { errorMessage } from '@/services/utils/message.service';

export const accountUserTransactionsGuard = async (): Promise<boolean> => {
  const transactionStore = useTransactionStore();
  if (!transactionStore.isLoaded) {
    const transactions = await userTransactions();
    if (transactions && transactionStore.isLoaded) {
      return true;
    }
    errorMessage('transactionErrorMessage');
    return false;
  }
  return true;
};
