import { AxiosError } from 'axios';
import { errorMessage } from '@/services/utils/message.service';
import { getApiClient } from './api.base';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { getSiteAffiliate } from '../session/affiliate.service';

import { AffiliateDetailsResponseModel } from '@/models/affiliate-details-response.model';

type Response = AffiliateDetailsResponseModel & AxiosError;

export const affiliateDetailsService = async (): Promise<Response> => {
    setLoading();
    try {
        const affiliate = getSiteAffiliate();
        const response = await getApiClient().get(
            `/api/v1/affiliate/info/${affiliate}`
        );
        clearLoading();
        return response.data as Response
    } catch (errorResponse) {
        const error = errorResponse as Response;
        errorMessage('accountUserSettingsErrorMessage');
        console.log(error);
        clearLoading();
        return error;
    }
};
