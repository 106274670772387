import { defineStore } from 'pinia';
import {
  syncUserStoreToStorage,
  removeStorageUser,
} from '@/services/session/user-storage-sync.service';
import { RegistrationUser } from '@/models/registration-user.model';
import {
  AccountUser,
  InitialAccountUserState,
} from '@/models/account-user.model';
import { LoginUser } from '@/models/login-user.model';

export interface UserStoreState {
  loaded: boolean;
  user: AccountUser;
}

export const useUserStore = defineStore('user', {
  state: (): UserStoreState => {
    return {
      loaded: false,
      user: {
        ...InitialAccountUserState,
      },
    } as UserStoreState;
  },
  getters: {
    isLoaded: (state) => state.loaded,
    getUserId: (state) => state.user.id,
    getName: (state) => [state.user.first_name, state.user.last_name].join(' '),
    getFirstName: (state) => state.user.first_name,
    getEmail: (state) => state.user.email,
    getTotalGifts: (state) => state.user.total_opp_amount,
    getNumberOfGifts: (state) => state.user.number_of_closed_opps,
    getTotalGiftsThisYear: (state) => state.user.opp_amount_this_year,
    getTotalGiftsLastYear: (state) => state.user.opp_amount_last_year,
    getTotalGiftsTwoYearsAgo: (state) => state.user.opp_amount_2_years_ago,
    getUserProjectsDonationsSumTotal: (state) => state.user.soft_credit_total,
    getUserProjectsDonationsTally: (state) => state.user.number_of_soft_credits,
    getHasActiveDonations: (state) => state.user.has_active_recurring_donation,
    getLastDonationDate: (state) => state.user.last_close_date,
    getFirstDonationDate: (state) => state.user.first_close_date,
    getCostPerChild: (state) => state.user.cost_per_child,
    getMailingStreet: (state) => state.user.mailing_street,
    getMailingCity: (state) =>  state.user.mailing_city,
    getMailingState: (state) =>  state.user.mailing_state ,
    getMailingPostalCode: state =>  state.user.mailing_postal_code,
    getMailingCountry: state => state.user.mailing_country,
  },
  actions: {
    setUser(user: AccountUser) {
      this.$patch({
        loaded: true,
        user: {
          ...user,
        },
      });
      syncUserStoreToStorage();
    },
    setUserFromLogin(user: AccountUser) {
      this.$patch({
        loaded: true,
        user: {
          ...user,
        },
      });
      syncUserStoreToStorage();
    },
    setUserNameFromLogin(user: LoginUser) {
      this.user.id = user.user_id;
      this.user.first_name = user.first_name;
      this.user.last_name = user.last_name;
      this.user.has_active_recurring_donation = user.has_active_recurring_donation;
      this.user.number_of_closed_opps = user.number_of_closed_opps;
      this.user.soft_credit_total = user.soft_credit_total;
      this.user.number_of_soft_credits = user.number_of_soft_credits;
      this.user.last_close_date = user.last_close_date;
      this.user.first_close_date = user.first_close_date;
      this.user.total_opp_amount = user.total_opp_amount;
      this.user.opp_amount_this_year = user.opp_amount_this_year;
      this.user.opp_amount_last_year = user.opp_amount_last_year;
      this.user.opp_amount_2_years_ago = user.opp_amount_2_years_ago;
      this.user.cost_per_child = user.cost_per_child;
      this.user.mailing_street = user.mailing_street;
      this.user.mailing_city = user.mailing_city;
      this.user.mailing_state = user.mailing_state;
      this.user.mailing_postal_code = user.mailing_postal_code;
      this.user.mailing_country = user.mailing_country;
      syncUserStoreToStorage();
    },
    setUserEmailFromRegister(user: RegistrationUser) {
      this.user.email = user.email;
      syncUserStoreToStorage();
    },
    setLoggedOut() {
      this.$reset();
      removeStorageUser();
    },
    setUserFromStorage(user: UserStoreState) {
      this.$patch({
        ...user,
      });
    },
  },
});
