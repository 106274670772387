import { loginService } from '@/services/api/login.service';
import { logoutService } from '@/services/api/logout.service';
import { registerService } from '@/services/api/register.service';
import { forgotPasswordService } from '@/services/api/forgot-password.service';
import { emailExistsService } from './email-exists.service';
import { userDetailsService } from './user.service';
import { fundraisingCreateService } from './fundraising-create.service';
import { changePasswordService } from './change-password.service';
import { affiliateDetailsService } from './affiliate-details.service';
import {
  marketingPreferencesService,
  updateMarketingPreferencesService,
} from './marketing-preferences.service';
import { userProjectsService } from './project-list.service';
import { fundraisingUpdateService } from './fundraising-update.service';
import { userProjectDonationsService } from './project-detail-donations.service';
import { userTransactionsService } from './user-transactions.service';

export const apiServices = {
  loginService,
  logoutService,
  registerService,
  forgotPasswordService,
  changePasswordService,
  affiliateDetailsService,
  marketingPreferencesService,
  updateMarketingPreferencesService,
  emailExistsService,
  userDetailsService,
  userProjectsService,
  fundraisingCreateService,
  fundraisingUpdateService,
  userProjectDonationsService,
  userTransactionsService,
};
