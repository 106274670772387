import { apiServices } from '@/services/api';
import { useProjectsStore } from '@/store/projects.store';
import { Project } from '@/models/project.model';
import { ProjectDonation } from '@/models/project-donations.model';
import { getFundraisingAssets } from '@/modules/fundraising-assets/services/session/fundraising-assets.service';
import { FUNDRAISING_ASSETS_BASE_URL } from '@/modules/fundraising-assets/config/fundraising-assets.config';
import { generatePaginationQuery } from '../utils/donation-pagination-query.service';

// API methods
export const userProjects = async (): Promise<boolean> => {
  const projects = await apiServices.userProjectsService();
  if (!projects.isAxiosError) {
    const projectStore = useProjectsStore();
    projectStore.setProjects(projects.data);
    return true;
  } else {
    return false;
  }
};

export const userProjectDonations = async (
  projectId: string
): Promise<boolean> => {
  const query = generatePaginationQuery();
  const donations = await apiServices.userProjectDonationsService(
    projectId,
    query
  );
  if (!donations.isAxiosError) {
    const projectStore = useProjectsStore();
    projectStore.setProjectDonations(donations.data);
    projectStore.setPaginationTotal(donations.meta[0].total ?? 0);
    return true;
  } else {
    return false;
  }
};

// Store methods
export const getProjects = (): Project[] => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjects;
};

export const getProjectDetail = (): Project => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjectDetail;
};

export const setProjectDetail = (project: Project): void => {
  const projectsStore = useProjectsStore();
  return projectsStore.setProjectDetail(project);
};

export const getProjectDonations = (): ProjectDonation[] => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjectDetailDonations;
};

// Pagination
export const resetPagination = (): void => {
  const projectsStore = useProjectsStore();
  projectsStore.resetProjectDetailPagination();
};

export const getPaginationPage = (): number => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjectDetailPagination.page;
};

export const setPaginationPage = (value: number): void => {
  const projectsStore = useProjectsStore();
  projectsStore.setPaginationPage(value);
};

export const getPaginationPageSize = (): number => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjectDetailPagination.pageSize;
};

export const getPaginationSort = (): string | undefined => {
  const projectsStore = useProjectsStore();
  return projectsStore.getProjectDetailPagination.sort;
};

// Asset methods
export const getProjectImage = (id?: string): string => {
  const legacyHeaderImageUrl = getProjectDetail().header_image_url;
  const defaultAsset = legacyHeaderImageUrl
    ? legacyHeaderImageUrl
    : getFundraisingAssets('Project')[2].full;
  const imageId = id ?? getProjectDetail().image_set_id;

  const asset = getFundraisingAssets('Project').filter(
    (item) => item.uuid === imageId
  );

  return asset[0]
    ? `${FUNDRAISING_ASSETS_BASE_URL}/${asset[0].full}`
    : defaultAsset;
};

export const getProjectAvatar = (id?: string): string => {
  const legacyHeaderImageUrl = getProjectDetail().sponsor_picture_id;
  const defaultAsset = legacyHeaderImageUrl
    ? legacyHeaderImageUrl
    : getFundraisingAssets('Avatar')[2].thumbnail;
  const imageId = id ?? getProjectDetail().sponsor_picture_id;

  const asset = getFundraisingAssets('Avatar').filter(
    (item) => item.uuid === imageId
  );

  return asset[0]
    ? `${FUNDRAISING_ASSETS_BASE_URL}/${asset[0].thumbnail}`
    : `${FUNDRAISING_ASSETS_BASE_URL}/${defaultAsset}`;
};
