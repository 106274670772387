import {
  MarketingPreferencesStoreState,
  useMarketingPreferencesStore,
} from '@/store/marketing-preferences.store';

export const getStorageMarketingPreferences =
  async (): Promise<MarketingPreferencesStoreState | null> => {
    const marketingPreferences = sessionStorage.getItem(
      'marketing-preferences'
    );
    if (marketingPreferences == undefined || marketingPreferences === '') {
      await removeStorageMarketingPreferences();
      return null;
    }
    return JSON.parse(
      atob(marketingPreferences)
    ) as MarketingPreferencesStoreState;
  };

export const setStorageMarketingPreferences = async (
  user: MarketingPreferencesStoreState
): Promise<void> => {
  sessionStorage.setItem('marketing-preferences', btoa(JSON.stringify(user)));
};

export const removeStorageMarketingPreferences = async (): Promise<void> => {
  sessionStorage.removeItem('marketing-preferences');
};

export const syncMarketingPreferencesStorageToStore =
  async (): Promise<boolean> => {
    const userStorage = await getStorageMarketingPreferences();
    if (userStorage?.loaded === true && userStorage?.preferences) {
      const userStore = useMarketingPreferencesStore();
      userStore.setMarketingPreferencesFromStorage(userStorage);
      return true;
    }
    return false;
  };

export const syncMarketingPreferencesStoreToStorage =
  async (): Promise<void> => {
    const marketingPreferencesStore = useMarketingPreferencesStore();
    setStorageMarketingPreferences({
      ...(marketingPreferencesStore.$state as MarketingPreferencesStoreState),
    });
  };
