import { getSiteAffiliate } from './services/session/affiliate.service';

export const languageMappings = (langCode: string): string => {
  const affiliate = getSiteAffiliate();
  if (langCode === 'en' && affiliate === 'USA') {
    return 'en-US'
  }
  if (langCode === 'en' && affiliate === 'Ireland') {
    return 'en-IE';
  }
  if (langCode === 'en') {
    langCode = 'en-GB';
  }

  if (langCode === 'de' && affiliate === 'Switzerland') {
    return 'ch-de'
  }
  return langCode;
};

export const getLanguageFile = async (
  languageCode: string
): Promise<unknown> => {
  const languageCodeForFile = languageMappings(languageCode);
  const langFile = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${languageCodeForFile}.json`
  );
  return {
    [languageCode]: langFile,
  };
};
