import { defineStore } from 'pinia';
import { FundraisingAssetItem } from '@/modules/fundraising-assets/models/fundraising-asset-item.model';

export interface FundraisingAssetStoreState {
  loaded: boolean;
  loading: boolean;
  selectedProjectImage: FundraisingAssetItem;
  selectedAvatarImage: FundraisingAssetItem;
  projectAssets: FundraisingAssetItem[];
  avatarAssets: FundraisingAssetItem[];
}

export const useFundraisingAssetStore = defineStore('fundraising-asset', {
  state: (): FundraisingAssetStoreState => {
    return {
      loaded: false,
      loading: false,
      selectedProjectImage: {} as FundraisingAssetItem,
      selectedAvatarImage: {} as FundraisingAssetItem,
      projectAssets: [],
      avatarAssets: [],
    } as FundraisingAssetStoreState;
  },
  getters: {
    getProjectAssets: (state) => state.projectAssets,
    getAvatarAssets: (state) => state.avatarAssets,
  },
  actions: {
    setProjectImage(image: FundraisingAssetItem) {
      this.selectedProjectImage = image;
    },
    setProjectAvatar(image: FundraisingAssetItem) {
      this.selectedAvatarImage = image;
    },
    clearSelected() {
      this.selectedProjectImage = {} as FundraisingAssetItem;
      this.selectedAvatarImage = {} as FundraisingAssetItem;
    },
  },
});
