export const getSiteAffiliate = (): string => {
  const affiliate =
    document.getElementById('app')?.getAttribute('data-affiliate') ?? '';

  if (affiliate === 'uk' || affiliate === 'mmi' || affiliate === 'usa' ) {
    return affiliate.toUpperCase();
  } else {
    return affiliate.charAt(0).toUpperCase() + affiliate.slice(1);
  }
};
interface countryObject {
  MMI: string;
  UK: string
  Germany: string;
  France: string;
  USA: string;
  Croatia: string;
  Switzerland: string;
  Poland: string;
  Spain: string;
  Ireland: string;
  Italy: string;
}
export const getSiteAffiliateLocale = (): string => {
  const siteAffiliate = getSiteAffiliate() as keyof countryObject;
  const countryLocales = {
    MMI: 'int',
    UK: 'gb',
    Germany: 'de',
    France: 'fr',
    USA: 'en-us',
    Croatia: 'hr',
    Switzerland: 'ch-de',
    Poland: 'pl',
    Spain: 'es',
    Ireland: 'ie',
    Italy: 'it'
  };
  return countryLocales[siteAffiliate];
};

export const getSiteAffiliateDonationUrl = (): string => {
  const devDonateUrl = 'https://dev-donate.marysmeals.org/';
  const uatDonateUrl= 'https://uat-donate.marysmeals.org/';
  const prodDonateUrl = 'https://donate.marysmeals.org/';

  // const dataHostUrl: string = document.getElementById('app')?.getAttribute('data-host') ?? '';
  const dataHostUrl = 'dev'; // for testing on dev only
  switch (true) {
    case dataHostUrl.includes('dev'):
      return devDonateUrl + getSiteAffiliateLocale();

    case dataHostUrl.includes('staging'):
      return uatDonateUrl + getSiteAffiliateLocale();

    default:
      return prodDonateUrl + getSiteAffiliateLocale();
  }
};

export const getAlternateAddressDisplay = (): boolean => {
  return getSiteAffiliate() === 'Germany';
};
