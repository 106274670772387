import { UserStoreState, useUserStore } from '@/store/user.store';

export const getStorageUser = async (): Promise<UserStoreState | null> => {
  const user = sessionStorage.getItem('user');
  if (user == undefined || user === '') {
    await removeStorageUser();
    return null;
  }
  return JSON.parse(atob(user)) as UserStoreState;
};

export const setStorageUser = async (user: UserStoreState): Promise<void> => {
  sessionStorage.setItem('user', btoa(JSON.stringify(user)));
};

export const removeStorageUser = async (): Promise<void> => {
  sessionStorage.removeItem('user');
};

export const syncUserStorageToStore = async (): Promise<boolean> => {
  const userStorage = await getStorageUser();
  if (userStorage?.user?.id) {
    const userStore = useUserStore();
    userStore.setUserFromStorage(userStorage);
    return true;
  }
  return false;
};

export const syncUserStoreToStorage = async (): Promise<void> => {
  const userStore = useUserStore();
  setStorageUser({
    ...(userStore.$state as UserStoreState),
  });
};
