import { defineStore } from 'pinia';
import { FundraisingProjectStep1 } from '@/models/fundraising-project-step1.model';
import { FundraisingProjectStep2 } from '@/models/fundraising-project-step2.model';
import { FundraisingProjectStep3 } from '@/models/fundraising-project-step3.model';

export interface FundraisingStoreState {
  step1: FundraisingProjectStep1;
  step2: FundraisingProjectStep2;
  step3: FundraisingProjectStep3;
  projectId?: string;
}

export const useFundraisingStore = defineStore('fundraising', {
  state: (): FundraisingStoreState => {
    return {
      step1: {
        amount: 500,
        projectName: '',
        projectAbout: '',
        projectImage: 'https://res.cloudinary.com/djyeswqkn/image/upload/v1727442516/gel7nyxp7rrvlrdcwvjc.jpg',
        projectAvatarImage: 'https://res.cloudinary.com/djyeswqkn/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1726501863/hold_heart_avatar_thyfwy.jpg',
        projectStartDate: '',
        projectEndDate: '',
        userAbout: '',
        userImage: '',
      },
      step2: {
        userAbout: '',
        userImage: '',
      },
      step3: {
        confirmPreview: false,
        project_template_id: 'Fundraising Project Template 1',
        category: 'General'
      },
      projectId: undefined,
    } as FundraisingStoreState;
  },
  getters: {
    getAmount: (state) => state.step1.amount,
    getStep1: (state) => state.step1,
    getStep2: (state) => state.step2,
    getStep3: (state) => state.step3,
    getProjectImage: (state) => state.step1.projectImage,
    getProjectAvatarImage: (state) => state.step1.projectAvatarImage
  },
  actions: {
    setConfirmedPreview() {
      this.step3.confirmPreview = true;
    },
  },
});
