import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { RegistrationResponse } from '@/models/registration-response.model';

type Response = RegistrationResponse & AxiosError;

export const registerService = async (
  formData: FormData
): Promise<Response> => {
  try {
    const response = await getApiClient().post('/api/v1/register', formData);
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    return error;
  }
};
