export enum Sort {
  New = 'newest-added',
  Old = 'oldest-first',
}

export interface TransactionsPagination {
  total: number;
  sort: Sort;
  page: number;
  pageSize: number;
}

export const initialValuesTransactionsPagination = {
  total: 0,
  sort: Sort.New,
  page: 0,
  pageSize: 5,
} as TransactionsPagination;
