import { useAuthStore } from '@/store/auth.store';

export const getStorageAuth = async (): Promise<string | null> => {
  const token = sessionStorage.getItem('token');
  if (token == undefined || token === '') {
    await removeStorageAuth();
    return null;
  }
  return token;
};

export const setStorageAuth = async (token: string): Promise<void> => {
  sessionStorage.setItem('token', token);
};

export const removeStorageAuth = async (): Promise<void> => {
  sessionStorage.removeItem('token');
};

export const syncAuthStorageToStore = async (): Promise<boolean> => {
  const token = await getStorageAuth();
  if (token) {
    const authStore = useAuthStore();
    authStore.setLoggedIn(token);
    return true;
  }
  return false;
};

export const syncAuthStoreToStorage = async (): Promise<void> => {
  const authStore = useAuthStore();
  if (authStore.getToken) {
    setStorageAuth(authStore.getToken);
  }
};
