import { defineStore } from 'pinia';
import {AffiliateOfferedMarketingPreferencesModel} from '@/models/affiliate-offered-marketing-preferences.model';

export interface AffiliateConfigStoreState {
    loaded: boolean,
    marketingPreferences: AffiliateOfferedMarketingPreferencesModel
}

export const useAffiliateConfigStore = defineStore('affiliate-config', {
    state: (): AffiliateConfigStoreState => {
        return {
            loaded: false,
            marketingPreferences: {
                email: true,
                phone: false,
                post: false
            } as AffiliateOfferedMarketingPreferencesModel
        } as AffiliateConfigStoreState
    },
    getters: {
        isLoaded: (state) => state.loaded,
        getOfferedMarketing: (state)  => state.marketingPreferences,
        getAffiliateOffersPost: (state) => state.marketingPreferences.post,
        getAffiliateOffersEmail: (state) => state.marketingPreferences.email,
        getAffiliateOffersPhone: (state) => state.marketingPreferences.phone,
    },
    actions: {
        setAffiliateMarketingOffers(offeredMarketing: AffiliateConfigStoreState) {
            this.$patch({
               ...offeredMarketing,
            });
        }
    },
});
