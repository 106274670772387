import { useMessageStore } from '@/store/message.store';

export const clearMessages = (): void => {
  const messageStore = useMessageStore();
  messageStore.$reset();
};

export const delayClearMessages = (waitTime = 5000): void => {
  const messageStore = useMessageStore();
  if (messageStore.hasMessage) {
    setTimeout(() => {
      clearMessages();
    }, waitTime);
  }
};

export const statusMessage = (message: string): void => {
  const messageStore = useMessageStore();
  messageStore.$patch({
    message,
    status: 'status',
  });
};

export const errorMessage = (message: string): void => {
  const messageStore = useMessageStore();
  messageStore.$patch({
    message,
    status: 'error',
  });
};
