/* eslint-disable @typescript-eslint/no-explicit-any */
import { MarketingPreferences } from '@/models/marketing-preferences.model';
import { getSiteAffiliate } from '@/services/session/affiliate.service';
import { useFundraisingStore } from '@/store/fundraising.store';
import { useMarketingPreferencesStore } from '@/store/marketing-preferences.store';

const DATALAYER_PUSH_BASIC = {
  event: '',
  affiliate_id: getSiteAffiliate(),
};

const getDataLayer = (): any => {
  try {
    return (top?.window as any).dataLayer || [];
  } catch (error) {
    console.info(error);
    return undefined;
  }
};

export const datalayerPush = (event: string): any => {
  const dataLayer = getDataLayer();
  const data = {
    ...DATALAYER_PUSH_BASIC,
    ...{ event },
  };
  dataLayer.push(data);
};

export const datalayerFundraisingProjectComplete = (): any => {
  const fundraisingStore = useFundraisingStore();
  const fundraisingData = fundraisingStore.$state;

  const dataLayer = getDataLayer();
  const data = {
    ...DATALAYER_PUSH_BASIC,
    ...{
      event: 'fundraisingProjectCreated',
      salesforce_campaign_id: fundraisingData.projectId,
      salesforce_campaign_name: fundraisingData.step1.projectName,
      fundraising_project_target: fundraisingData.step1.amount,
      fundraising_project_start_date: fundraisingData.step1.projectStartDate,
      fundraising_project_end_date: fundraisingData.step1.projectEndDate ?? '',
    },
  };
  dataLayer.push(data);
};

export const datalayerAccountPreferencesUpdate = (
  marketingPreferences: MarketingPreferences
): any => {
  const marketingPreferencesStore = useMarketingPreferencesStore();
  const marketingPreferencesStoreData = {
    ...marketingPreferencesStore.$state.preferences,
  };

  const dataLayer = getDataLayer();
  const data = {
    ...DATALAYER_PUSH_BASIC,
    ...{
      event: 'myAccountPreferencesUpdate',
      email_preference:
        marketingPreferencesStoreData.email === marketingPreferences.email
          ? undefined
          : marketingPreferences.email
          ? 'Opted In'
          : 'Opted Out',
      post_preference:
        marketingPreferencesStoreData.post === marketingPreferences.post
          ? undefined
          : marketingPreferences.post
          ? 'Opted In'
          : 'Opted Out',
    },
  };
  dataLayer.push(data);
};
