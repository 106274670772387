export interface ProjectDonationsPagination {
  total: number;
  sort?: string;
  page: number;
  pageSize: number;
}

export const initialValuesProjectDonationsPagination = {
  total: 0,
  sort: undefined,
  page: 0,
  pageSize: 5,
} as ProjectDonationsPagination;
