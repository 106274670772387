import {AffiliateConfigStoreState, useAffiliateConfigStore} from '@/store/affiliate-config.store';
import { affiliateDetailsService } from '@/services/api/affiliate-details.service';
import {AffiliateDetailsResponseModel} from '@/models/affiliate-details-response.model';
import {AffiliateOfferedMarketingPreferencesModel} from '@/models/affiliate-offered-marketing-preferences.model';

export const affiliateOfferedMarketingGuard = async (): Promise<boolean> => {
    const affiliateConfigStore = await useAffiliateConfigStore();
    if (!affiliateConfigStore.isLoaded) {
        const affiliateDetails = await affiliateDetailsService() as AffiliateDetailsResponseModel;
        if (affiliateDetails) {
            const storeNewState = {
                loaded: true,
                marketingPreferences: {
                    email: affiliateDetails.data.affiliate_offers_email_marketing,
                    post: affiliateDetails.data.affiliate_offers_post_marketing,
                    phone: affiliateDetails.data.affiliate_offers_sms_marketing,
                } as AffiliateOfferedMarketingPreferencesModel
            } as AffiliateConfigStoreState;
            affiliateConfigStore.setAffiliateMarketingOffers(storeNewState);
            return true;
        } else {
            return false
        }
    }
    return true;
};
