import { defineStore } from 'pinia';
import {
  InitialAddressLookupParams,
  AddressLookupResult,
  AddressLookupParams,
  Address,
} from '@/modules/address-lookup/services/address-lookup-api.config';

export interface AddressLookupStoreState {
  searching: boolean;
  searchParams: AddressLookupParams;
  results?: AddressLookupResult[];
  address?: Address;
}

export const useAddressLookupStore = defineStore('postcode', {
  state: (): AddressLookupStoreState => {
    return {
      searching: false,
      searchParams: {
        ...InitialAddressLookupParams,
      },
      results: [] as AddressLookupResult[],
      address: undefined,
    } as AddressLookupStoreState;
  },
  getters: {
    getSearching: (state) => state.searching,
    getSearchParams: (state) => state.searchParams,
    getResults: (state) => state.results,
    getAddress: (state) => state.address,
  },
});
