import { AxiosError } from 'axios';
import { getApiClient } from './api.base';
import { getStoredToken } from '../session/account-store.service';
import { FundraisingUpdatePayload } from '@/models/fundraising-update-payload.model';
import { FundraisingCreateResponse } from '@/models/fundraising-create-response.model';
import { clearLoading, setLoading } from '@/services/utils/loading.service';

type Response = FundraisingCreateResponse & AxiosError;

export const fundraisingUpdateService = async (
  projectId: string,
  payload: FundraisingUpdatePayload
): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const response = await getApiClient('auth', token ?? '').patch(
      `/api/v1/account/project/${projectId}`,
      payload
    );
    // const apiClient = getApiClient('auth', token ?? '');
    // const response = await apiClient.patch(
    //   `http://localhost:5000/account/project/${projectId}`,
    //   payload
    // );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
