import { useUserStore } from '@/store/user.store';
import { syncUserStorageToStore } from '@/services/session/user-storage-sync.service';

export const accountUserHomeGuard = async (): Promise<boolean> => {
  const userStore = useUserStore();
  if (!userStore.isLoaded) {
    await syncUserStorageToStore();
  }
  return true;
};
