import { useAuthStore } from '@/store/auth.store';
import { useUserStore } from '@/store/user.store';
import { useMarketingPreferencesStore } from '@/store/marketing-preferences.store';
import { Login } from '@/models/login.model';
import { AccountUser } from '@/models/account-user.model';
import { MarketingPreferences } from '@/models/marketing-preferences.model';

export const setLoginEmail = async (values: Login): Promise<void> => {
  const authStore = useAuthStore();
  authStore.setEmail(values.email);
};

export const getStoredUser = (): AccountUser => {
  const userStore = useUserStore();
  return userStore.user;
};

export const getStoredUserId = (): number => {
  const userStore = useUserStore();
  return userStore.getUserId;
};

export const getStoredUserName = (): string => {
  const userStore = useUserStore();
  return userStore.getName;
};

export const getStoredUserFirstName = (): string => {
  const userStore = useUserStore();
  return userStore.getFirstName;
};

export const getStoredTotalGifts = (): number => {
  const userStore = useUserStore();
  return userStore.getTotalGifts;
};

export const getHasActiveRecurringDonations = (): boolean => {
  const userStore = useUserStore();
  return userStore.getHasActiveDonations;
};

export const getStoredTotalGiftsThisYear = (): number => {
  const userStore = useUserStore();
  return userStore.getTotalGiftsThisYear;
};
export const getStoredTotalGiftsLastYear = (): number => {
  const userStore = useUserStore();
  return userStore.getTotalGiftsLastYear;
};
export const getTotalGiftsTwoYearsAgo = (): number => {
  const userStore = useUserStore();
  return userStore.getTotalGiftsTwoYearsAgo;
};
export const totalNumberOfGiftsMade = (): number => {
  const userStore = useUserStore();
  return userStore.getNumberOfGifts;
};
export const getUserProjectsDonationSumTotal = (): number => {
  const userStore = useUserStore();
  return userStore.getUserProjectsDonationsSumTotal;
};
export const getUserProjectsDonationTally = (): number => {
  const userStore = useUserStore();
  return userStore.getUserProjectsDonationsTally;
};

export const getLastDonationDate = (): string => {
  const userStore = useUserStore();
  return userStore.getLastDonationDate;
};

export const getFirstDonationDate = (): string => {
  const userStore = useUserStore();
  return userStore.getFirstDonationDate;
};


export const getMailingStreet = (): string => {
  const userStore = useUserStore();
  return userStore.getMailingStreet;
};

export const getMailingCity = (): string => {
  const userStore = useUserStore();
  return userStore.getMailingCity;
};

export const getMailingState = (): string => {
  const userStore = useUserStore();
  return userStore.getMailingState;
};

export const getMailingPostalCode = (): string => {
  const userStore = useUserStore();
  return userStore.getMailingPostalCode;
};

export const getMailingCountry = (): string => {
  const userStore = useUserStore();
  return userStore.getMailingCountry;
};



export const getStoredEmail = (): string | undefined => {
  const authStore = useAuthStore();
  return authStore.getEmail;
};



export const getStoredToken = (): string | undefined => {
  const authStore = useAuthStore();
  return authStore.getToken;
};

export const getStoredMarketingPreferences = (): MarketingPreferences => {
  const marketingPreferencesStore = useMarketingPreferencesStore();
  return marketingPreferencesStore.getMarketingPreferences;
};

export const setStoredMarketingPreferences = async (
  preferences: MarketingPreferences
): Promise<void> => {
  const marketingPreferencesStore = useMarketingPreferencesStore();
  marketingPreferencesStore.setMarketingPreferences(preferences);
};
