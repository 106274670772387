import { useAppStore } from '@/store/app.store';

export const clearLoading = (): void => {
  const appStore = useAppStore();
  appStore.$reset();
};

export const setLoading = (): void => {
  const appStore = useAppStore();
  appStore.setLoading();
};
