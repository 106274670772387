import {
  getPaginationPage,
  getPaginationPageSize,
  getPaginationSort,
} from '../session/transactions.service';

export const generatePaginationQuery = (): string => {
  let query: string[] = [];

  query = queryPusher(query, addSort());
  query = queryPusher(query, addPage());
  query = queryPusher(query, addPageSize());

  return query.join('&');
};

const queryPusher = (query: string[], param: string | undefined): string[] => {
  if (param) {
    query.push(param);
  }
  return query;
};

const addSort = (): string | undefined => {
  const sort = getPaginationSort();
  return sort ? `sort=${sort}` : undefined;
};

const addPage = (): string | undefined => {
  const page = getPaginationPage();
  return page > 0 ? `page=${page}` : 'page=0';
};

const addPageSize = (): string | undefined => {
  const pagesize = getPaginationPageSize();
  return pagesize ? `pagesize=${pagesize}` : undefined;
};
