import { defineStore } from 'pinia';
import { MarketingPreferences } from '@/models/marketing-preferences.model';
import {
  removeStorageMarketingPreferences,
  syncMarketingPreferencesStoreToStorage,
} from '@/services/session/marketing-preferences-storage-sync.service';

export interface MarketingPreferencesStoreState {
  loaded: boolean;
  preferences: MarketingPreferences;
}

export const useMarketingPreferencesStore = defineStore(
  'marketing-preferences',
  {
    state: (): MarketingPreferencesStoreState => {
      return {
        loaded: false,
        preferences: {} as MarketingPreferences,
      } as MarketingPreferencesStoreState;
    },
    getters: {
      isLoaded: (state) => state.loaded,
      getMarketingPreferences: (state) => state.preferences,
    },
    actions: {
      setMarketingPreferences(preferences: MarketingPreferences) {
        this.loaded = true;
        this.preferences = preferences;
        syncMarketingPreferencesStoreToStorage();
      },
      setLoggedOut() {
        this.$reset();
        removeStorageMarketingPreferences();
      },
      setMarketingPreferencesFromStorage(
        preferences: MarketingPreferencesStoreState
      ) {
        this.$patch({
          ...preferences,
        });
      },
    },
  }
);
