export const ADDRESS_LOOKUP_API_SEARCH_URL =
  'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws';
export const ADDRESS_LOOKUP_API_RETRIEVE_URL =
  'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws';
const POSTCODE_API_KEY = process.env.VUE_APP_POSTCODE_API_KEY;
export const ADDRESS_LOOKUP_RESULT_LIMIT = 30;
export const ADDRESS_LOOKUP_MIN_SEARCH_LENGTH = 3;
export const ADDRESS_LOOKUP_DEFAULT_LOCALE = 'en';

// AddressLookup

export interface AddressLookupParams {
  Key: string;
  Text: string;
  IsMiddleware: boolean;
  Container: string;
  Origin: string;
  Limit: number;
  Language: string;
  Bias: boolean;
}

export const InitialAddressLookupParams: AddressLookupParams = {
  Key: POSTCODE_API_KEY,
  Text: '',
  IsMiddleware: false,
  Container: '',
  Origin: '',
  Limit: ADDRESS_LOOKUP_RESULT_LIMIT,
  Language: ADDRESS_LOOKUP_DEFAULT_LOCALE,
  Bias: true,
};

export interface AddressLookupResult {
  Id: string;
  Type: string;
  Description: string;
  Text: string;
  Highlight: string;
}

export interface AddressLookupResponse {
  Items: AddressLookupResult[];
}

export interface Address {
  address1: string;
  address2: string;
  townCity: string;
  region: string;
  postcode: string;
  country: string;
}

export interface AddressPayload {
  firstLine: string;
  city: string;
  region: string;
  postCode: string;
  country: string;
}
