import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { useAppStore } from '@/store/app.store';

type Response = boolean & AxiosError;

export const clearValidating = (): void => {
  const appStore = useAppStore();
  appStore.$reset();
};

export const setValidating = (): void => {
  const appStore = useAppStore();
  appStore.setValidating();
};

export const emailExistsService = async (
  formData: FormData
): Promise<Response> => {
  setValidating();
  try {
    const response = await getApiClient().post(
      '/api/v1/email-exists',
      formData
    );
    clearValidating();
    return response.data[0] as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearValidating();
    return error;
  }
};
