const titleSeparator = ' - ';
// eslint-disable-next-line quotes
const baseTitles = ['Account', "Mary's Meals"];

export const setTitle = (...titles: string[]): void => {
  const title: string = [...titles, ...baseTitles].join(titleSeparator);
  announceTitleChange(title);
  document.title = title;
};

export const announceTitleChange = (title: string): void => {
  const announcer = document.getElementById('account-aria-live');
  if (announcer) {
    announcer.setAttribute('aria-live', 'off');

    const clearAnnouncer = () => {
      announcer.innerHTML = '';
    };

    const manners = 'polite';
    announcer.setAttribute('aria-live', manners);
    announcer.innerHTML = title;
    setTimeout(clearAnnouncer, 500);
  }
};
