/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  AddressLookupStoreState,
  useAddressLookupStore,
} from '@/modules/address-lookup/store/address.store';
import {
  ADDRESS_LOOKUP_API_SEARCH_URL,
  ADDRESS_LOOKUP_API_RETRIEVE_URL,
  InitialAddressLookupParams,
  AddressLookupResponse,
  AddressLookupResult,
  Address,
} from '@/modules/address-lookup/services/address-lookup-api.config';
import { errorMessage } from '@/services/utils/message.service';

const apiClient: AxiosInstance = axios.create({
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
  },
});

export const buildSearchParams = (
  postcode = '',
  container = ''
): URLSearchParams => {
  const addressLookupStore = useAddressLookupStore();
  const params = new URLSearchParams();
  const lookupParams = {
    ...addressLookupStore.getSearchParams,
  };
  if (postcode) {
    lookupParams.Text = postcode;
  }
  if (container) {
    lookupParams.Container = container;
  }
  for (const [key, value] of Object.entries(lookupParams)) {
    params.append(key, String(value));
  }
  return params;
};

export const postcodeSearch = async (
  params: URLSearchParams
): Promise<AddressLookupResponse> => {
  setSearching(true);
  return apiClient
    .post(ADDRESS_LOOKUP_API_SEARCH_URL, params)
    .then(function (response: AxiosResponse) {
      return response.data;
    })
    .catch(function (error) {
      console.info(error);
      errorMessage('postcodeSearchErrorMessage');
      return error;
    })
    .finally(function () {
      setSearching(false);
    });
};

export const setPostCodeSearchResults = (results: any[]): void => {
  const addressLookupStore = useAddressLookupStore();
  addressLookupStore.$patch({
    results: results,
  } as AddressLookupStoreState);
};

export const addressRetrieve = async (
  id: string
): Promise<AddressLookupResponse> => {
  setSearching(true);
  const addressLookupStore = useAddressLookupStore();
  const lookupParams = addressLookupStore.getSearchParams;
  const params = new URLSearchParams();
  params.append('Key', lookupParams.Key);
  params.append('Id', id);
  return apiClient
    .post(ADDRESS_LOOKUP_API_RETRIEVE_URL, params)
    .then(function (response: AxiosResponse) {
      return response.data;
    })
    .catch(function (error) {
      console.info(error);
      errorMessage('addressRetrieveErrorMessage');
      return error;
    })
    .finally(function () {
      setSearching(false);
    });
};

export const getAddress = (): Address | undefined => {
  const addressLookupStore = useAddressLookupStore();
  return addressLookupStore.getAddress;
};

export const setAddress = (address: Address): void => {
  const addressLookupStore = useAddressLookupStore();
  addressLookupStore.$patch({
    searchParams: {
      ...InitialAddressLookupParams,
    },
    results: [] as AddressLookupResult[],
    address: address,
  } as AddressLookupStoreState);
};

export const resetAddressLookupSearch = (): void => {
  const addressLookupStore = useAddressLookupStore();
  addressLookupStore.$patch({
    searching: false,
    searchParams: {
      ...InitialAddressLookupParams,
    },
    results: [] as AddressLookupResult[],
    address: undefined,
  } as AddressLookupStoreState);
};

export const setSearching = (searching: boolean): void => {
  const addressLookupStore = useAddressLookupStore();
  addressLookupStore.$patch({
    searching,
  } as AddressLookupStoreState);
};
