import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { getStoredToken } from '../session/account-store.service';
import { clearLoading, setLoading } from '@/services/utils/loading.service';

type Response = boolean & AxiosError;

export const logoutService = async (): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const response = await getApiClient('auth', token ?? '').post(
      '/api/v1/logout'
    );
    clearLoading();
    if (response.status === 204) {
      return true as Response;
    }
    return false as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
