import { useNavigationStore } from '@/store/responsive-navigation.store';
import { syncNavigationStoreToStorage } from '../session/responsive-navigation-storage-sync.service';

export const isMobile = (): boolean => {
  let mobile = false;
  const elements = Array.from(
    document.getElementsByClassName(
      'header__nav-toggle'
    ) as HTMLCollectionOf<HTMLElement>
  );

  if (elements[0] && window.getComputedStyle(elements[0]).display === 'block') {
    mobile = true;
  }
  return mobile;
};

export const getActiveMenu = (): number => {
  const navigationStore = useNavigationStore();
  return navigationStore.getActiveMenu;
};

export const setActiveMenu = async (menuActive: number): Promise<number> => {
  const navigationStore = useNavigationStore();
  navigationStore.$patch({
    activeMenu: menuActive,
  });
  const mobile = isMobile();
  if (mobile) {
    collapseMenuDisplay();
  }
  await syncNavigationStoreToStorage();
  return navigationStore.getActiveMenu;
};

export const updateMenuVisibility = (): void => {
  const mobile = isMobile();
  if (mobile) {
    collapseMenuDisplay();
  } else {
    expandMenuDisplay();
  }
};

export const toggleMenuDisplay = (): void => {
  const navigationStore = useNavigationStore();
  if (navigationStore.isExpandedMenu) {
    collapseMenuDisplay();
  } else {
    expandMenuDisplay();
  }
};

const collapseMenuDisplay = (): void => {
  const elements = document.getElementsByClassName('account-nav__item');
  for (const item of elements) {
    item.setAttribute('style', 'display: none;');
  }
  elements[getActiveMenu() - 1].setAttribute('style', 'display: block;');
  const navigationStore = useNavigationStore();
  navigationStore.$patch({
    expandedMenu: false,
  });
};

const expandMenuDisplay = (): void => {
  const elements = document.getElementsByClassName('account-nav__item');
  for (const item of elements) {
    item.setAttribute('style', 'display: block;');
  }
  const navigationStore = useNavigationStore();
  navigationStore.$patch({
    expandedMenu: true,
  });
};
