import { useUserStore } from '@/store/user.store';
import { errorMessage } from '@/services/utils/message.service';
import { syncUserStorageToStore } from '@/services/session/user-storage-sync.service';
import { userDetails } from '@/services/session/account.service';

export const accountUserSettingsGuard = async (): Promise<boolean> => {
  const userStore = useUserStore();
  if (!userStore.isLoaded) {
    const sync = await syncUserStorageToStore();
    if (sync && userStore.isLoaded) {
      return true;
    }
    const user = await userDetails();
    if (user && userStore.isLoaded) {
      return true;
    }
    errorMessage('accountUserSettingsErrorMessage');
    return false;
  }
  return true;
};
