import { defineStore } from 'pinia';
import { Project } from '@/models/project.model';
import { ProjectDonation } from '@/models/project-donations.model';
import {
  initialValuesProjectDonationsPagination,
  ProjectDonationsPagination,
} from '@/models/project-donations-pagination.model';
import { userProjectDonations } from '@/services/session/projects.service';

export interface ProjectStoreState {
  loaded: boolean;
  projects: Project[];
  projectDetail: Project;
  projectDetailDonations: ProjectDonation[];
  projectDetailDonationsPagination: ProjectDonationsPagination;
}

export const useProjectsStore = defineStore('projects', {
  state: (): ProjectStoreState => {
    return {
      loaded: false,
      projects: [] as Project[],
      projectDetail: {} as Project,
      projectDetailDonations: [] as ProjectDonation[],
      projectDetailDonationsPagination: initialValuesProjectDonationsPagination,
    } as ProjectStoreState;
  },
  getters: {
    isLoaded: (state) => state.loaded,
    getProjects: (state) => state.projects,
    getProjectDetail: (state) => state.projectDetail,
    getProjectDetailDonations: (state) => state.projectDetailDonations,
    getProjectDetailPagination: (state) =>
      state.projectDetailDonationsPagination,
  },
  actions: {
    setProjects(projects: Project[]) {
      this.$patch({
        loaded: true,
        projects: projects,
      });
    },
    setProjectDetail(project: Project) {
      this.$patch({
        projectDetail: project,
      });
    },
    setProjectDonations(projectDonations: ProjectDonation[]) {
      this.$patch({
        projectDetailDonations: projectDonations,
      });
    },
    resetProjectDetailPagination() {
      this.$patch({
        projectDetailDonations: [] as ProjectDonation[],
        projectDetailDonationsPagination: {
          total: 0,
          sort: undefined,
          page: 0,
          pageSize: 5,
        } as ProjectDonationsPagination,
      });
    },
    setLoggedOut() {
      this.$reset();
    },
    setProjectsFromStorage(projects: ProjectStoreState) {
      this.$patch({
        ...projects,
      });
    },
    setPaginationTotal(value: number) {
      const pagination = this.getProjectDetailPagination;
      this.$patch({
        projectDetailDonationsPagination: {
          ...pagination,
          ...{
            total: value,
          },
        },
      });
    },
    setPaginationPageSize(value: number) {
      const pagination = this.getProjectDetailPagination;
      this.$patch({
        projectDetailDonationsPagination: {
          ...pagination,
          ...{
            pageSize: value,
          },
        },
      });
      userProjectDonations(this.getProjectDetail.id);
    },
    setPaginationPage(value: number) {
      const pagination = this.getProjectDetailPagination;
      this.$patch({
        projectDetailDonationsPagination: {
          ...pagination,
          ...{
            page: value,
          },
        },
      });
      userProjectDonations(this.getProjectDetail.id);
    },
    setPaginationSort(value: string) {
      const pagination = this.getProjectDetailPagination;
      this.$patch({
        projectDetailDonationsPagination: {
          ...pagination,
          ...{
            sort: value,
          },
        },
      });
      userProjectDonations(this.getProjectDetail.id);
    },
  },
});
