import { useProjectsStore } from '@/store/projects.store';
import { userProjects } from '@/services/session/projects.service';
import { errorMessage } from '@/services/utils/message.service';
import { checkAndLoadFundraisingAssets } from '@/modules/fundraising-assets/services/session/fundraising-assets.service';

export const accountUserProjectsGuard = async (): Promise<boolean> => {
  const projectStore = useProjectsStore();
  if (!projectStore.isLoaded) {
    const projects = await userProjects();
    if (projects && projectStore.isLoaded) {
      await checkAndLoadFundraisingAssets();
      return true;
    }
    errorMessage('fundraisingProjectsErrorMessage');
    return false;
  }
  return true;
};
