import {
  FundraisingAssetStoreState,
  useFundraisingAssetStore,
} from '../../store/fundraising-asset.store';

export const getStorageFundraisingAssets =
  async (): Promise<FundraisingAssetStoreState | null> => {
    const fundraising = sessionStorage.getItem('fundraising-assets');
    if (fundraising == undefined || fundraising === '') {
      await removeStorageFundraisingAssets();
      return null;
    }
    return JSON.parse(atob(fundraising)) as FundraisingAssetStoreState;
  };

export const setStorageFundraisingAssets = async (
  fundraising: FundraisingAssetStoreState
): Promise<void> => {
  removeStorageFundraisingAssets();
  sessionStorage.setItem(
    'fundraising-assets',
    btoa(JSON.stringify(fundraising))
  );
};

export const removeStorageFundraisingAssets = async (): Promise<void> => {
  sessionStorage.removeItem('fundraising-assets');
};

export const syncFundraisingAssetsStorageToStore =
  async (): Promise<boolean> => {
    const fundraising = await getStorageFundraisingAssets();
    if (fundraising) {
      const fundraisingStore = useFundraisingAssetStore();
      fundraisingStore.$patch(fundraising);
      return true;
    }
    return false;
  };

export const syncFundraisingAssetsStoreToStorage = async (): Promise<void> => {
  const fundraisingStore = useFundraisingAssetStore();
  setStorageFundraisingAssets({
    ...(fundraisingStore.$state as FundraisingAssetStoreState),
  });
};
