import {
  NavigationStoreState,
  useNavigationStore,
} from '@/store/responsive-navigation.store';

export const getStorageNavigation =
  async (): Promise<NavigationStoreState | null> => {
    const navigation = sessionStorage.getItem('navigation-state');
    if (navigation == undefined || navigation === '') {
      await removeStorageNavigation();
      return null;
    }
    return JSON.parse(atob(navigation)) as NavigationStoreState;
  };

export const setStorageNavigation = async (
  navigation: NavigationStoreState
): Promise<void> => {
  sessionStorage.setItem('navigation-state', btoa(JSON.stringify(navigation)));
};

export const removeStorageNavigation = async (): Promise<void> => {
  sessionStorage.removeItem('navigation-state');
};

export const syncNavigationStorageToStore = async (): Promise<boolean> => {
  const navigation = await getStorageNavigation();
  if (navigation) {
    const navigationStore = useNavigationStore();
    navigationStore.$patch(navigation);
    return true;
  }
  return false;
};

export const syncNavigationStoreToStorage = async (): Promise<void> => {
  const navigationStore = useNavigationStore();
  setStorageNavigation({
    ...(navigationStore.$state as NavigationStoreState),
  });
};
