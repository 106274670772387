import { defineStore } from 'pinia';

interface MessageStore {
  message?: string;
  status: string;
}

export const useMessageStore = defineStore('message', {
  state: (): MessageStore => {
    return {
      message: undefined,
      status: 'status',
    } as MessageStore;
  },
  getters: {
    getMessage: (state) => state.message,
    getStatus: (state) => state.status,
    hasMessage: (state) => (state.message ? true : false),
  },
});
