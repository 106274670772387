import { FUNDRAISING_ASSETS_IMAGE_TYPES } from '../../config/fundraising-assets.config';
import { FundraisingAssetItem } from '../../models/fundraising-asset-item.model';
import { fundraisingAssetService } from '../api/fundraising-asset.service';
import {
  FundraisingAssetStoreState,
  useFundraisingAssetStore,
} from '../../store/fundraising-asset.store';
import {
  syncFundraisingAssetsStorageToStore,
  syncFundraisingAssetsStoreToStorage,
} from './fundraising-assets-storage-sync.service';

export const checkAndLoadFundraisingAssets = async (): Promise<void> => {
  if (!getFundraisingAssetsLoaded()) {
    await syncFundraisingAssetsStorageToStore();
    if (!getFundraisingAssetsLoaded()) {
      const loaded = await loadFundraisingAssets();
      if (!loaded) {
        console.error('Could not load Fundraising Assets');
      }
    }
  }
};

export const loadFundraisingAssets = async (): Promise<boolean> => {
  setFundraisingAssetsLoading(true);
  const assets = await fundraisingAssetService();
  if (!assets.isAxiosError) {
    const fundraisingStore = useFundraisingAssetStore();
    fundraisingStore.$patch({
      projectAssets: assets.Project,
      avatarAssets: assets.Avatar,
    });
    setFundraisingAssetsLoaded(true);
    syncFundraisingAssetsStoreToStorage();
    return true;
  }

  setFundraisingAssetsLoaded(false);
  setFundraisingAssetsLoading(false);
  return false;
};

export const getFundraisingAssetStore = (): FundraisingAssetStoreState => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.$state;
};

export const getFundraisingAssets = (
  imageType: FUNDRAISING_ASSETS_IMAGE_TYPES
): FundraisingAssetItem[] => {
  switch (imageType) {
    case 'Project':
    default:
      return getFundraisingAssetsProject();
    case 'Avatar':
      return getFundraisingAssetsAvatar();
  }
};

export const getFundraisingAssetsProject = (): FundraisingAssetItem[] => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.getProjectAssets;
};

export const getFundraisingAssetsAvatar = (): FundraisingAssetItem[] => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.getAvatarAssets;
};

export const getFundraisingAssetsLoading = (): boolean => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.loading;
};

export const setFundraisingAssetsLoading = (value: boolean): void => {
  const fundraisingStore = useFundraisingAssetStore();
  fundraisingStore.$patch({
    loading: value,
    loaded: value === true ? false : fundraisingStore.loaded,
  });
  syncFundraisingAssetsStoreToStorage();
};

export const getFundraisingAssetsLoaded = (): boolean => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.loaded;
};

export const setFundraisingAssetsLoaded = (value: boolean): void => {
  const fundraisingStore = useFundraisingAssetStore();
  fundraisingStore.$patch({
    loaded: value,
    loading: value === true ? false : fundraisingStore.loading,
  });
  syncFundraisingAssetsStoreToStorage();
};

export const getSelectedImage = (
  imageType: FUNDRAISING_ASSETS_IMAGE_TYPES
): FundraisingAssetItem => {
  switch (imageType) {
    case 'Project':
    default:
      return getProjectImage();
    case 'Avatar':
      return getProjectAvatar();
  }
};

export const setSelectedImage = (
  imageType: FUNDRAISING_ASSETS_IMAGE_TYPES,
  image: FundraisingAssetItem
): void => {
  switch (imageType) {
    case 'Project':
      setProjectImage(image);
      break;
    case 'Avatar':
      setProjectAvatar(image);
      break;
  }
  syncFundraisingAssetsStoreToStorage();
};

const setProjectImage = (image: FundraisingAssetItem): void => {
  const fundraisingStore = useFundraisingAssetStore();
  fundraisingStore.setProjectImage(image);
};

const getProjectImage = (): FundraisingAssetItem => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.selectedProjectImage;
};

const setProjectAvatar = (image: FundraisingAssetItem): void => {
  const fundraisingStore = useFundraisingAssetStore();
  fundraisingStore.setProjectAvatar(image);
};

const getProjectAvatar = (): FundraisingAssetItem => {
  const fundraisingStore = useFundraisingAssetStore();
  return fundraisingStore.selectedAvatarImage;
};

export const clearSelectedImages = (): void => {
  const fundraisingStore = useFundraisingAssetStore();
  fundraisingStore.clearSelected();
  syncFundraisingAssetsStoreToStorage();
};
