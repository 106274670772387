import { defineStore } from 'pinia';
import { Transaction } from '@/models/transaction.model';
import {
  initialValuesTransactionsPagination,
  Sort,
  TransactionsPagination,
} from '@/models/transactions-pagination.model';

export interface TransactionStoreState {
  loaded: boolean;
  transactions: Transaction[];
  transactionsPagination: TransactionsPagination;
}

export const useTransactionStore = defineStore('transactions', {
  state: (): TransactionStoreState => {
    return {
      loaded: false,
      transactions: [] as Transaction[],
      transactionsPagination: initialValuesTransactionsPagination,
    } as TransactionStoreState;
  },
  getters: {
    isLoaded: (state) => state.loaded,
    getTransactions: (state) => state.transactions,
    getTransactionsPagination: (state) => state.transactionsPagination,
  },
  actions: {
    setTransactions(transactions: Transaction[]) {
      this.$patch({
        loaded: true,
        transactions: transactions,
      });
    },
    setLoggedOut() {
      this.$reset();
    },
    setTransactionsFromStorage(projects: TransactionStoreState) {
      this.$patch({
        ...projects,
      });
    },
    setPaginationTotal(value: number) {
      const pagination = this.getTransactionsPagination;
      this.$patch({
        transactionsPagination: {
          ...pagination,
          ...{
            total: value,
          },
        },
      });
    },
    setPaginationPageSize(value: number) {
      const pagination = this.getTransactionsPagination;
      this.$patch({
        transactionsPagination: {
          ...pagination,
          ...{
            pageSize: value,
          },
        },
      });
    },
    setPaginationPage(value: number) {
      const pagination = this.getTransactionsPagination;
      this.$patch({
        transactionsPagination: {
          ...pagination,
          ...{
            page: value,
          },
        },
      });
    },
    setPaginationSort(value: Sort) {
      const pagination = this.getTransactionsPagination;
      this.$patch({
        transactionsPagination: {
          ...pagination,
          ...{
            page: 0,
            sort: value,
          },
        },
      });
    },
  },
});
