import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { getStoredToken } from '../session/account-store.service';
import { getSiteAffiliate } from '../session/affiliate.service';
import { AccountMarketingPreferencesUpdateRequest } from '@/models/account-marketing-preferences-update-request.model';
import { AccountMarketingPreferencesResponse } from '@/models/account-marketing-preferences-response.model';

type Response = AccountMarketingPreferencesResponse & AxiosError;

export const marketingPreferencesService = async (): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const affiliate = getSiteAffiliate();
    const response = await getApiClient('auth', token ?? '').get(
      `/api/v1/account/marketing-preferences/${affiliate}`
    );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};

export const updateMarketingPreferencesService = async (
  payload: AccountMarketingPreferencesUpdateRequest
): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const affiliate = getSiteAffiliate();
    const response = await getApiClient('auth', token ?? '').put(
      `/api/v1/account/marketing-preferences/${affiliate}`,
      payload
    );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
