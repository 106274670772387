import axios, { AxiosInstance, AxiosError } from 'axios';
import { FundraisingAssetAvatar } from '../../models/fundraising-asset-avatar.model';
import { FundraisingAssetProject } from '../../models/fundraising-asset-project.model';

export interface FundraisingAssetsResponse {
  Project: FundraisingAssetProject[];
  Avatar: FundraisingAssetAvatar[];
}

type Response = FundraisingAssetsResponse & AxiosError;

const apiClient = (): AxiosInstance => {
  return axios.create({
    headers: {
      'Content-type': 'application/json',
    },
  });
};

export const fundraisingAssetService = async (): Promise<Response> => {
  try {
    const assetsUrl = getAssetsUrl();
    const response = await apiClient().get(assetsUrl);
    return response.data as Response;
  } catch (errorResponse) {
    return errorResponse as Response;
  }
};

const getAssetsUrl = (): string => {
  // const host = document.getElementById('app')?.getAttribute('data-host') ?? '';
  const host = 'dev.marysmeals.org';
  const assetsPath = getAssetsPath('default');
  return `//${host}/${assetsPath}`;
};

const getAssetsPath = (siteId: string): string => {
  return `sites/${siteId}/files/fundraising_assets/fundraising-assets.json`;
};
