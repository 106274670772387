import { useMarketingPreferencesStore } from '@/store/marketing-preferences.store';
import { errorMessage } from '@/services/utils/message.service';
import { userMarketingPreferences } from '@/services/session/account.service';
import { syncMarketingPreferencesStorageToStore } from '@/services/session/marketing-preferences-storage-sync.service';

export const accountMarketingPreferencesGuard = async (): Promise<boolean> => {
  const marketingPreferencesStore = useMarketingPreferencesStore();
  if (!marketingPreferencesStore.isLoaded) {
    const sync = await syncMarketingPreferencesStorageToStore();
    if (sync && marketingPreferencesStore.isLoaded) {
      return true;
    }

    const marketingPreferences = await userMarketingPreferences();
    if (marketingPreferences && marketingPreferencesStore.isLoaded) {
      return true;
    }

    errorMessage('accountUserSettingsErrorMessage');
    return false;
  }
  return true;
};
