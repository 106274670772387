import { apiServices } from '@/services/api';
import { useTransactionStore } from '@/store/transactions.store';
import { Transaction } from '@/models/transaction.model';
import { Sort } from '@/models/transactions-pagination.model';
import { generatePaginationQuery } from '../utils/transactions-pagination-query.service';

// API methods
export const userTransactions = async (): Promise<boolean> => {
  const query = generatePaginationQuery();
  const transaction = await apiServices.userTransactionsService(query);
  if (!transaction.isAxiosError) {
    const transactionStore = useTransactionStore();
    transactionStore.setTransactions(transaction.data);
    transactionStore.setPaginationTotal(transaction.meta[0].total ?? 0);
    return true;
  } else {
    return false;
  }
};

// Store methods
export const getTransactions = (): Transaction[] => {
  const transactionStore = useTransactionStore();
  return transactionStore.getTransactions;
};

// Pagination
export const getPaginationPage = (): number => {
  const transactionStore = useTransactionStore();
  return transactionStore.getTransactionsPagination.page;
};

export const setPaginationPage = (value: number): void => {
  const transactionStore = useTransactionStore();
  transactionStore.setPaginationPage(value);
};

export const getPaginationPageSize = (): number => {
  const transactionStore = useTransactionStore();
  return transactionStore.getTransactionsPagination.pageSize;
};

export const getPaginationSort = (): Sort => {
  const transactionStore = useTransactionStore();
  return transactionStore.getTransactionsPagination.sort;
};

export const setSearchSort = (sort: Sort): void => {
  const transactionStore = useTransactionStore();
  transactionStore.setPaginationSort(sort);
};
