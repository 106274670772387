import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  RouterView,
} from 'vue-router';
import {
  loggedOutGuard,
  loggedInGuard,
  authEmailGuard,
} from '@/guards/auth-status.guard';
import { logout } from '@/services/session/account.service';
import { delayClearMessages } from '@/services/utils/message.service';
import { setTitle } from '@/services/utils/a11y-title.service';
import { syncFundraisingStorageToStore } from '@/services/session/fundraising-storage-sync.service';
import { syncFundraisingAssetsStorageToStore } from '@/modules/fundraising-assets/services/session/fundraising-assets-storage-sync.service';
import { checkStep1 } from '@/guards/fundraising-add.guard';
import { accountUserSettingsGuard } from '@/guards/account-user-settings.guard';
import { accountMarketingPreferencesGuard } from '@/guards/account-marketing-preferences.guard';
import { accountUserHomeGuard } from '@/guards/account-user-home.guard';
import { accountUserProjectsGuard } from '@/guards/account-user-projects.guard';
import { accountUserTransactionsGuard } from '@/guards/account-user-transaction.guard';
import {affiliateOfferedMarketingGuard} from '@/guards/affiliate-config.guard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/fundraising',
    name: 'Fundraising',
    component: RouterView,
    beforeEnter: async (to, from, next) => {
      const pathName = to.name ? to.name.toString() : 'Home';
      const redirect = await loggedOutGuard(pathName);
      if (redirect) {
        next({ name: redirect });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'add/step1',
        alias: 'add',
        name: 'Add Step 1',
        component: () =>
          import(
            /* webpackChunkName: "fundraising-project-step1" */ '../views/FundraisingProjectStep1.vue'
          ),
        beforeEnter: async () => {
          await syncFundraisingStorageToStore();
          await syncFundraisingAssetsStorageToStore();
        },
      },
      // {
      //   path: 'add/step2',
      //   name: 'Add Step 2',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "fundraising-project-step2" */ '../views/FundraisingProjectStep2.vue'
      //     ),
      //   beforeEnter: async (to, from, next) => {
      //     await syncFundraisingStorageToStore();
      //     await syncFundraisingAssetsStorageToStore();
      //     const complete = await checkStep1();
      //     if (!complete) {
      //       next({ name: 'Add Step 1' });
      //     } else {
      //       next();
      //     }
      //   },
      // },
      {
        path: 'add/step3',
        name: 'Add Step 3',
        component: () =>
          import(
            /* webpackChunkName: "fundraising-project-step2" */ '../views/FundraisingProjectStep3.vue'
          ),
        beforeEnter: async (to, from, next) => {
          await syncFundraisingStorageToStore();
          await syncFundraisingAssetsStorageToStore();
          const complete = await checkStep1();
          if (!complete) {
            next({ name: 'Add Step 3' });
          } else {
            next();
          }
        },
      },
      {
        path: 'add/complete',
        name: 'Complete',
        component: () =>
          import(
            /* webpackChunkName: "fundraising-project-complete" */ '../views/FundraisingProjectComplete.vue'
          ),
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedOutGuard('Home');
      if (redirect) {
        next({ name: redirect });
      } else {
        await accountUserHomeGuard();
        next();
      }
    },
  },
  {
    path: '/projects',
    name: 'Account Projects',
    component: () =>
      import(
        /* webpackChunkName: "account-projects" */ '../views/AccountProjects.vue'
      ),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedOutGuard('Account Projects');
      if (redirect) {
        next({ name: redirect });
      } else {
        const projects = await accountUserProjectsGuard();
        if (!projects) {
          next({ name: 'Home' });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/transactions',
    name: 'Account Transactions',
    component: () =>
      import(
        /* webpackChunkName: "account-transactions" */ '../views/AccountTransactions.vue'
      ),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedOutGuard('Account Transactions');
      if (redirect) {
        next({ name: redirect });
      } else {
        const transactions = await accountUserTransactionsGuard();
        if (!transactions) {
          next({ name: 'Home' });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/settings',
    name: 'Account Settings',
    component: () =>
      import(
        /* webpackChunkName: "account-settings" */ '../views/AccountSettings.vue'
      ),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedOutGuard('Account Settings');
      if (redirect) {
        next({ name: redirect });
      } else {
        const affiliateConfig = await affiliateOfferedMarketingGuard();
        const user = await accountUserSettingsGuard();
        const marketingPreferences = await accountMarketingPreferencesGuard();
        if (!user && !marketingPreferences && !affiliateConfig) {
          next({ name: 'Home' });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    beforeEnter: async (to, from, next) => {
      const affiliateConfig = await affiliateOfferedMarketingGuard();
      const redirect = await loggedInGuard();
      const hasEmail = await authEmailGuard();
      if (redirect) {
        next({ name: redirect });
      } else if (!hasEmail) {
        next({ name: 'Login' });
      } else if (affiliateConfig) {
        next();
      }
    },
  },
  {
    path: '/login-standard',
    name: 'Login Standard',
    component: () =>
      import(/* webpackChunkName: "login-standard" */ '../views/Login.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedInGuard();
      const hasEmail = await authEmailGuard();
      if (redirect) {
        next({ name: redirect });
      } else if (!hasEmail) {
        next({ name: 'Login' });
      } else {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginEmail.vue'),
    beforeEnter: async (to, from, next) => {
      const redirect = await loggedInGuard();
      if (redirect) {
        next({ name: redirect });
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: async (to, from, next) => {
      await logout();
      setTitle('Login');
      next({ name: 'Login' });
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)',
    name: '404 Not Found',
    component: () =>
      import(
        /* webpackChunkName: "404-not-found" */ '../views/404NotFound.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const pathName = to.name ? to.name.toString() : '';
  setTitle(pathName);
});
router.afterEach(() => {
  delayClearMessages();
});

export default router;
