import {
  FundraisingStoreState,
  useFundraisingStore,
} from '@/store/fundraising.store';

export const getStorageFundraising =
  async (): Promise<FundraisingStoreState | null> => {
    const fundraising = sessionStorage.getItem('fundraising');
    if (fundraising == undefined || fundraising === '') {
      await removeStorageFundraising();
      return null;
    }
    return JSON.parse(atob(fundraising)) as FundraisingStoreState;
  };

export const setStorageFundraising = async (
  fundraising: FundraisingStoreState
): Promise<void> => {
  sessionStorage.setItem('fundraising', btoa(JSON.stringify(fundraising)));
};

export const removeStorageFundraising = async (): Promise<void> => {
  sessionStorage.removeItem('fundraising');
};

export const syncFundraisingStorageToStore = async (): Promise<boolean> => {
  const fundraising = await getStorageFundraising();
  if (fundraising) {
    const fundraisingStore = useFundraisingStore();
    fundraisingStore.$patch(fundraising);
    return true;
  }
  return false;
};

export const syncFundraisingStoreToStorage = async (): Promise<void> => {
  const fundraisingStore = useFundraisingStore();
  setStorageFundraising({
    ...(fundraisingStore.$state as FundraisingStoreState),
  });
};
