import { defineStore } from 'pinia';

export interface NavigationStoreState {
  expandedMenu: boolean;
  activeMenu: number;
}

export const useNavigationStore = defineStore('navigation', {
  state: (): NavigationStoreState => {
    return {
      expandedMenu: false,
      activeMenu: 1,
    } as NavigationStoreState;
  },
  getters: {
    getActiveMenu: (state) => state.activeMenu,
    isExpandedMenu: (state) => state.expandedMenu,
  },
});
