import { defineStore } from 'pinia';
import {
  syncAuthStoreToStorage,
  removeStorageAuth,
} from '@/services/session/auth-storage-sync.service';

interface AuthStore {
  loggedIn: boolean;
  email?: string;
  token?: string;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStore => {
    return {
      loggedIn: false,
      email: undefined,
      token: undefined,
    } as AuthStore;
  },
  getters: {
    isLoggedIn: (state) => state.loggedIn,
    getToken: (state) => state.token,
    getEmail: (state) => state.email,
  },
  actions: {
    setEmail(email: string) {
      this.email = email;
    },
    setLoggedIn(access_token: string) {
      this.loggedIn = true;
      this.token = access_token;
      this.email = undefined;
      syncAuthStoreToStorage();
    },
    setLoggedOut() {
      this.$reset();
      removeStorageAuth();
    },
  },
});
