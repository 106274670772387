import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from './api.config';

export const getApiClient = (
  typOfApi?: string,
  token?: string
): AxiosInstance => {
  if (!typOfApi) {
    return apiClientBasic();
  }

  let apiClient: AxiosInstance;
  switch (typOfApi) {
    case 'auth':
      apiClient = apiClientAuthorised(token ?? '');
      break;
    case 'search':
      apiClient = apiClientSearch();
      break;
    case 'mockAuth':
    default:
      apiClient = mockApiClientAuthorised(token ?? '');
      break;
  }
  return apiClient;
};

const apiClientBasic = (): AxiosInstance => {
  return axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      'Content-type': 'application/json',
    },
  });
};

const apiClientSearch = (): AxiosInstance => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-type': 'application/json',
      'cloud-search-api': '40b7b8d3-8f36-4922-a456-efb18fc284e1', // TODO move to env
    },
  });
};

const apiClientAuthorised = (token: string): AxiosInstance => {
  return axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
};

const mockApiClientAuthorised = (token: string): AxiosInstance => {
  return axios.create({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  });
};
