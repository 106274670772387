import { AxiosError } from 'axios';

import { getApiClient } from './api.base';
import { getStoredToken } from '../session/account-store.service';
import { clearLoading, setLoading } from '@/services/utils/loading.service';
import { AccountProjectsResponse } from '@/models/account-projects-response.model';

type Response = AccountProjectsResponse & AxiosError;

export const userProjectsService = async (): Promise<Response> => {
  setLoading();
  try {
    const token = getStoredToken();
    const response = await getApiClient('auth', token ?? '').get(
      '/api/v1/account/projects'
    );
    clearLoading();
    return response.data as Response;
  } catch (errorResponse) {
    const error = errorResponse as Response;
    console.info(error);
    clearLoading();
    return error;
  }
};
